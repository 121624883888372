
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /**
 * This is a catch all for storyblok pages any storyblok page
 * placed in a folder outside the community pages will
 * be routed according to the folder structure.
 *
 * It assumes that the page will always have a locale prefix
 * on its slug e.g. fr/mypage/about-evs
 * The locale prefix comes from the directory structure
 * defined in Storyblok.
 */
import * as Sentry from '@sentry/nextjs';
import { ISbStoryParams as StoryParams } from 'storyblok-js-client';
import { useRouter } from 'next/router';
import { Storyblok, useStoryblok } from '@electro/consumersite/src/storyblok';
import { PageLayout } from '@electro/consumersite/src/storyblok/bloks';
import dirtyReplaceForChargerCount from '@electro/consumersite/src/storyblok/helpers/dirtyReplaceForChargerCount';
import { getDatasourceEntries } from '@electro/consumersite/src/storyblok/util/storyblok';
interface landingPagePageProps {
    story: StoryParams;
}
const IGNORED_PATHS = ['community/'];
export default function Page({ story }: landingPagePageProps) {
    const router = useRouter();
    const enableBridge = '_storyblok' in router.query;
    const storyBlok = useStoryblok(story, enableBridge);
    return <PageLayout blok={storyBlok.content}/>;
}
async function getStaticProps({ params, preview = false, locale, defaultLocale }) {
    try {
        const slug = params?.slug ? params.slug.join('/') : 'home';
        const sbParams: StoryParams = {
            version: 'published',
        };
        if (preview) {
            sbParams.version = 'draft';
            sbParams.cv = Date.now();
        }
        const localisedSlug = `${locale}/${slug}`;
        const { data } = await Storyblok.get(`cdn/stories/${localisedSlug}`, sbParams);
        /**
         * Storyblok won't let us add a datasource to a text item directly in it's UI.
         * To work around this we are adding our own template code.
         * It's nothing more that a dirty replace (I know!) for a single value.
         */
        const chargerCountDataSource = await getDatasourceEntries('charger-count');
        const chargerCount = chargerCountDataSource.find((item) => item.name === 'total');
        const chargerCountUK = chargerCountDataSource.find((item) => item.name === 'UK');
        const contentWithChargerCount = dirtyReplaceForChargerCount(data.story, chargerCount.value, chargerCountUK.value);
        return {
            props: {
                /**
                 * We need to provide a key here or links between
                 * dynamically generated pages will not work!
                 * https://github.com/vercel/next.js/issues/9992#issuecomment-615402511
                 */
                key: data.story.id,
                slug,
                story: contentWithChargerCount,
                preview,
            },
            revalidate: 3600,
        };
    }
    catch (err) {
        Sentry.captureException(err, (scope) => scope.setExtras({ params }));
        throw new Error(err);
    }
}
/**
 * In storyblok we have dynamic localised directories for each region.
 * This allows us to have custom content for each country.
 * The slugs in storybook look like this
 * /fr/route/path
 * next-translate needs a locale for every page. This means that we have
 * to specify the country code based on the locale in the slug and then remove it
 * from the paths array because next-translate adds it back in after.
 * This avoids static pages being generate on routes like /fr/fr/route/path
 */
export async function getStaticPaths() {
    try {
        const { data } = await Storyblok.get('cdn/links/');
        const paths = [];
        Object.keys(data.links).forEach((linkKey) => {
            if (data.links[linkKey].is_folder ||
                IGNORED_PATHS.some((slug) => data.links[linkKey].slug.includes(slug))) {
                return;
            }
            const { slug, isStartpage } = data.links[linkKey];
            /**
             * We are assuming the country code always prefixes the slug.
             * This is because all content in Storyblok is organised into
             * directories named after country codes.
             * https://www.storyblok.com/docs/guide/in-depth/internationalization#folder-level-translation
             */
            const [countryCodeFromSlug] = slug.split('/');
            const isHomePageSlug = slug.split('/')[1] === 'home';
            const splitSlug = isHomePageSlug || isStartpage ? [countryCodeFromSlug] : slug.split('/');
            const index = splitSlug.indexOf(countryCodeFromSlug);
            if (index !== -1) {
                splitSlug.splice(index, 1);
            }
            paths.push({ params: { slug: splitSlug }, locale: countryCodeFromSlug });
        });
        return {
            paths,
            fallback: false,
        };
    }
    catch (err) {
        Sentry.captureException(err);
        throw new Error(err);
    }
}

    async function __Next_Translate__getStaticProps__1932f4bdd19__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[[...slug]]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1932f4bdd19__ as getStaticProps }
  